import { accountRegisterPath } from "@api/account";
import { postUserSettings, userSettingsPath } from "@api/profile";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useIsImperial from "@hooks/useIsImperial";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import reportError from "@lib/util/reportError";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "next-i18next";
import { MouseEvent, useEffect, useState } from "react";
import { useSWRConfig } from "swr";

type UnitsOfMeasureInputProps = {
	useMetric: boolean | undefined;
};
export default function UnitsOfMeasureInput({ useMetric = false }: UnitsOfMeasureInputProps) {
	const { t } = useTranslation("common");
	const { mutate } = useSWRConfig();
	const updateAlert = useUpdateAlert();
	const isImperial = useIsImperial();
	const [optimisticallyMetric, setOptimisticallyMetric] = useState<boolean | null>(!isImperial);
	useGlobalLoader(optimisticallyMetric != null, "UnitsOfMeasureInput updating");

	useEffect(() => {
		setOptimisticallyMetric(null);
	}, [useMetric]);

	const displayValue = optimisticallyMetric != null ? optimisticallyMetric : useMetric;

	function handleChange(event: MouseEvent<HTMLElement>, newUnits: "imperial" | "metric" | null) {
		if (newUnits == null) {
			return;
		}
		setOptimisticallyMetric(newUnits === "metric");
		postUserSettings({ account_settings: { units_override: newUnits } })
			.then(() => {
				mutate(accountRegisterPath);
				mutate(userSettingsPath);
			})
			.catch((error) => {
				reportError(error);
				updateAlert(t("unknown_error"));
				setOptimisticallyMetric(null);
			});
	}
	return (
		<ToggleButtonGroup
			disabled={optimisticallyMetric != null}
			value={displayValue ? "metric" : "imperial"}
			exclusive
			onChange={handleChange}
		>
			<ToggleButton value="imperial">{t("account_settings.imperial")}</ToggleButton>
			<ToggleButton value="metric">{t("account_settings.metric")}</ToggleButton>
		</ToggleButtonGroup>
	);
}
