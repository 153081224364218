import { updateAlertType } from "@lib/feature/alert/types";
import reportError from "@lib/util/reportError";
import { useTranslation } from "next-i18next";
import { useCallback, useRef, useState } from "react";
import { useSWRConfig } from "swr";

export default function useMutation(swrKey: string, updateAlert: updateAlertType) {
	const { t } = useTranslation("common");
	const [mutating, setMutating] = useState(false);
	const { mutate: swrMutate } = useSWRConfig();
	const queueRef = useRef<(() => Promise<void>)[]>([]);
	const currentUpdateRef = useRef<Promise<void> | null>(null);

	const mutate = useCallback(async () => {
		const update = queueRef.current.shift();
		if (update == null) {
			return;
		}
		setMutating(true);
		const error = await update().then(
			() => undefined,
			(error) => error,
		);
		if (error != null) {
			reportError(error);
			updateAlert(t("unknown_error"));
		}
		if (queueRef.current.length > 0) {
			currentUpdateRef.current = mutate();
			await currentUpdateRef.current;
			return;
		}
		currentUpdateRef.current = null;
		await swrMutate(swrKey);
		if (currentUpdateRef.current == null) {
			setMutating(false);
		}
	}, [swrMutate, swrKey, t, updateAlert]);

	return {
		mutating,
		mutate: useCallback(
			(update: () => Promise<void>) => {
				queueRef.current.push(update);
				if (currentUpdateRef.current == null) {
					currentUpdateRef.current = mutate();
				}
			},
			[mutate],
		),
	};
}
