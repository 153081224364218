import CloseButton from "@components/CloseButton";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { styled } from "@mui/material";
import { MouseEvent, ReactNode } from "react";

export type PaneHeadingProps = {
	onClose?: (event: MouseEvent, reason: "backdropClick" | "escapeKeyDown" | "closeButtonClick") => void;
	title?: ReactNode;
	titleId?: string;
	subtitle?: ReactNode;
	subtitleId?: string;
	className?: string;
};

const PaneHeading = styled(function ({ title, subtitle, titleId, subtitleId, onClose, className }: PaneHeadingProps) {
	return (
		<div className={className}>
			<div>
				{title == null ? null : <h2 id={titleId}>{title}</h2>}
				{subtitle == null ? null : <p id={subtitleId}>{subtitle}</p>}
			</div>
			{onClose == null ? null : (
				<CloseButton
					onClick={(event) => onClose(event, "closeButtonClick")}
					sx={{
						position: "static",
						top: "auto",
						right: "auto",
						flex: "0 0 auto",
					}}
				/>
			)}
		</div>
	);
})({
	display: "flex",
	gap: "4px",

	div: {
		display: "flex",
		flexDirection: "column",
		gap: "4px",
		flex: "1 1 auto",
	},

	h2: {
		margin: 0,
		fontFamily: vcrOsdMono.style.fontFamily,
		padding: "0px",
		fontWeight: "400",
		fontSize: "20px",
	},
});

export default PaneHeading;
