import { accountRegisterPath } from "@api/account";
import { UpdateLocationRandomizationRequest, updateLocationRandomization } from "@api/profile";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import DialogButton from "@components/DialogButton";
import { indicateLoading } from "@components/GlobalLoader";
import ArrowsPointingOutIcon from "@components/icons/ArrowsPointingOutIcon";
import LocationRandomizationSlider from "@components/slider/LocationRandomizationSlider";
import useLocation from "@hooks/useLocation";
import useUser from "@hooks/useUser";
import useAlertState from "@lib/feature/alert/useAlertState";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import { getMap } from "@lib/feature/map/mapbox";
import mapConfig from "@lib/feature/map/mapbox/mapConfig";
import reportError from "@lib/util/reportError";
import { track } from "@lib/util/trackAnalytics";
import { DialogActions, MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useSWRConfig } from "swr";

type AppMenuLocationRandomization = {
	onClose: () => void;
};

export default function AppMenuLocationRandomization({ onClose: menuOnClose }: AppMenuLocationRandomization) {
	const { t } = useTranslation("common");
	const user = useUser();
	const { mutate } = useSWRConfig();
	const [value, setValue] = useState(user?.location_randomization ?? 0);
	const [alert, updateAlert] = useAlertState();
	const [, position] = useLocation();
	const [loading, setLoading] = useState(false);
	const featureFlags = useFeatureFlags();

	return (
		<DialogButton
			renderDialog={(open, onClose) => {
				async function handleSave() {
					setLoading(true);
					const stopLoading = indicateLoading("location randomization request");
					try {
						const params: UpdateLocationRandomizationRequest = { location_randomization: value };

						if (position != null) {
							params.lat = position.coords.latitude;
							params.lng = position.coords.longitude;
							params.source = "precise";
						} else if (featureFlags?.ip_geolocated_location != null) {
							params.lat = featureFlags.ip_geolocated_location.lat;
							params.lng = featureFlags.ip_geolocated_location.lng;
							params.source = "ip";
						}

						await updateLocationRandomization(params);
						track("location_randomized", { distance_selected: value });
						onClose();
					} catch (error) {
						reportError(error as Error);
						updateAlert(t("unknown_error"));
					} finally {
						await mutate(accountRegisterPath);
						stopLoading();
						setLoading(false);
						if (user?.lng != null && user?.lat != null) {
							const mapbox = getMap();
							mapbox?.easeTo({ center: [user.lng, user.lat], zoom: mapConfig.zoom });
						}
					}
				}

				return (
					<Dialog
						open={open}
						onClose={onClose}
						title={t("location_randomization.dialog_title")}
						subtitle={t("location_randomization.dialog_body_1")}
					>
						<LocationRandomizationSlider
							value={value}
							onChange={(_event, value) => {
								if (typeof value == "number") {
									setValue(value);
								}
							}}
						/>
						<DialogActions>
							<Button type="submit" variant="primary" onClick={handleSave} disabled={loading}>
								{t("save_button")}
							</Button>
						</DialogActions>
						{alert == null ? undefined : (
							<Alert
								key={alert.key}
								severity={alert.severity}
								body={alert.children}
								onClose={() => {
									updateAlert();
								}}
							/>
						)}
					</Dialog>
				);
			}}
			renderButton={(onOpen) => (
				<MenuItem
					component="span"
					onClick={() => {
						menuOnClose();
						onOpen();
					}}
				>
					<ArrowsPointingOutIcon />
					{t("app_bar.location_privacy")}
				</MenuItem>
			)}
			dialogRoutingName="location-randomization"
		/>
	);
}
