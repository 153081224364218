import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box, SvgIconProps, Typography } from "@mui/material";
import palette from "@styles/palette";
type InformationPaneProps = {
	Icon: (props: SvgIconProps) => JSX.Element;
	title: string;
	subtitle: string;
};

export default function InformationPane({ title, subtitle, Icon }: InformationPaneProps) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "12px",
				px: "20px",
				my: "24px",
			}}
		>
			<Icon sx={{ width: "52px", height: "52px", color: palette.Primary }} />
			<Typography variant="h6" textAlign="center" sx={{ fontFamily: vcrOsdMono.style.fontFamily }}>
				{title}
			</Typography>
			<Typography sx={{ textAlign: "center", fontSize: "16px" }}>{subtitle}</Typography>
		</Box>
	);
}
