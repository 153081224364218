import { accountRegisterPath } from "@api/account";
import { deleteProfileImage, updatePinnedPicture } from "@api/profile";
import useMutation from "@hooks/useMutation";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { track } from "@lib/util/trackAnalytics";

export default function usePhotoUpdate(uuid: string) {
	const updateAlert = useUpdateAlert();
	const { mutating: saving, mutate: mutateUser } = useMutation(accountRegisterPath, updateAlert);

	function setPinned(pinned: boolean) {
		mutateUser(async () => {
			await updatePinnedPicture({ uuid, pinned });

			track(pinned ? "profile_photo_pinned" : "profile_photo_unpinned");
		});
	}

	function deletePhoto() {
		mutateUser(async () => {
			await deleteProfileImage({ uuid });

			track("profile_photo_deleted");
		});
	}

	return {
		saving,
		setPinned,
		deletePhoto,
	};
}
