import Pane from "@components/Pane";
import { PaneSection } from "@components/Pane/PaneSection";
import useIdify from "@hooks/useIdify";
import safeSx from "@lib/util/safeSx";
import { Modal, ModalProps, styled } from "@mui/material";
import { ComponentProps, ElementType, ReactNode } from "react";
import PaneHeading, { PaneHeadingProps } from "./Pane/PaneHeading";

const DialogContainer = styled("div")({
	position: "absolute",
	left: "0px",
	right: "0px",
	top: "var(--safe-top)",
	bottom: 0, // not safe area because absolute is relative to <html>, which currently
	// doesn't cover the bottom safe area.
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	padding: "20px",
	pointerEvents: "none",
});

type DialogProps = Omit<ModalProps, "children" | "title" | "onClose"> &
	PaneHeadingProps & {
		title: string;
		children: ReactNode;
		contentComponent?: ElementType;
		dialogContainerProps?: ComponentProps<typeof DialogContainer>;
		dialogBodyProps?: ComponentProps<typeof Pane>;
		dialogContentProps?: ComponentProps<typeof PaneSection>;
	};

export default function Dialog({
	children,
	title,
	subtitle,
	onClose,
	contentComponent = "div",
	dialogContainerProps,
	dialogBodyProps,
	dialogContentProps,
	sx,
	...props
}: DialogProps) {
	const idify = useIdify();
	const titleId = idify("title");
	const subtitleId = idify("subtitle");
	return (
		<Modal onClose={onClose} {...props}>
			<DialogContainer {...dialogContainerProps}>
				<Pane
					role="dialog"
					tabIndex={-1}
					aria-labelledby={titleId}
					aria-describedby={subtitle == null ? undefined : subtitleId}
					aria-modal="true"
					{...dialogBodyProps}
					sx={safeSx(
						{
							pointerEvents: "auto",
							maxHeight: "100%",
							overflowY: "auto",
						},
						sx,
					)}
				>
					<PaneHeading title={title} titleId={titleId} subtitle={subtitle} subtitleId={subtitleId} onClose={onClose} />
					<PaneSection as={contentComponent} {...dialogContentProps}>
						{children}
					</PaneSection>
				</Pane>
			</DialogContainer>
		</Modal>
	);
}
