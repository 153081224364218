import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box, styled } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import BackButtonV2 from "./BackButtonV2";
import { LinkButton } from "./Button";
import CloseButton from "./CloseButton";
import ChevronLeftIcon from "./icons/ChevronLeftIcon";

const Heading = styled("h1")({
	margin: 0,
	fontSize: "20px",
	fontFamily: vcrOsdMono.style.fontFamily,
	fontWeight: 400,
	color: palette.onSurface,
});

type BackButtonTarget = "back" | "map";

type HeaderBarProps = {
	backButtonTarget?: BackButtonTarget;
	customHeader?: ReactNode;
	heading?: ReactNode;
	onClose?: () => void;
};

export default function HeaderBar({ backButtonTarget, customHeader = null, heading, onClose }: HeaderBarProps) {
	const { t } = useTranslation("common");
	const navigate = useNavigate();
	const showBottomBorder = heading != null;

	return (
		<Box
			component="header"
			sx={{
				backgroundColor: palette.surfaceContainer,
				borderBottom: showBottomBorder ? `1px solid ${palette.onSurface}` : undefined,
				display: "flex",
				alignItems: "center",
				gap: "4px",
				pt: "env(safe-area-inset-top, 0)",
			}}
		>
			{(() => {
				if (backButtonTarget === "back") {
					return (
						<BackButtonV2
							sx={{
								ml: 1,
							}}
							onClick={() => {
								navigate(-1);
							}}
						/>
					);
				}
				if (backButtonTarget === "map") {
					return (
						<LinkButton icon variant="secondary ghost" sx={{ ml: 1 }} aria-label={t("nav.map_link_label")} to="/map">
							<ChevronLeftIcon />
						</LinkButton>
					);
				}
				return null;
			})()}
			{heading == null ? null : <Heading>{heading}</Heading>}
			{customHeader}
			{onClose == null ? null : (
				<CloseButton
					onClick={onClose}
					sx={{
						position: "static",
						flex: "0 0 auto",
					}}
				/>
			)}
		</Box>
	);
}
