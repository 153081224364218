import { accountRegisterPath } from "@api/account";
import useIdify from "@hooks/useIdify";
import useUser from "@hooks/useUser";
import { ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "next-i18next";
import SaveSwitch from "./SaveSwitch";

export default function VisibilitySettings() {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const isAdmin = useUser()?.is_admin ?? false;

	const settingsList = [
		{
			accountSetting: "visible_as_breadcrumb",
			label: t("account_settings.visible_as_breadcrumb_label") as string,
			extraMutationKeys: [],
		},
		// TODO: re-enable removed settings when query is performant enough
		// {
		// 	accountSetting: "only_visible_to_registered_users",
		// 	label: t("account_settings.visible_to_only_registered_label") as string,
		// 	extraMutationKeys: [],
		// },
		...(isAdmin
			? ([
					// {
					// 	accountSetting: "only_visible_to_admin_users",
					// 	label: t("account_settings.visible_to_only_admin_label"),
					// 	extraMutationKeys: [],
					// },
					{
						accountSetting: "blur_map_pins",
						label: t("account_settings.blur_map_pins_label"),
						extraMutationKeys: [accountRegisterPath],
					},
					{
						accountSetting: "admin_clustering_enabled",
						label: t("account_settings.admin_clustering_enabled_label"),
						extraMutationKeys: [accountRegisterPath],
					},
				] as const)
			: []),
	] as const;
	return settingsList.map(({ accountSetting, label, extraMutationKeys }) => {
		const labelId = idify(accountSetting);
		return (
			<ListItem
				key={accountSetting}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<ListItemText id={labelId} primary={label} />
				{/* ListItemSecondaryAction is what MUI expects you to use here, but the
											implementation for it uses absolute positioning + transform to position it
											centered on the right, and the transform breaks fixed positioning for any children,
											which we need for the Loader component. Instead we're using flex on the parent with
											space-between. */}
				<SaveSwitch labelId={labelId} accountSetting={accountSetting} extraMutationKeys={extraMutationKeys} />
			</ListItem>
		);
	});
}
