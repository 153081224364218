import { accountRegisterPath } from "@api/account";
import { updateProfile } from "@api/profile";
import useAccount from "@hooks/useAccount";
import AgeVerificationField from "@lib/auth/Signup/AgeVerificationField";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import assertType from "@lib/util/assertType";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { Box, Button } from "@mui/material";
import { deepStrictEqual } from "assert";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";
import { useSWRConfig } from "swr";
import AboutMeField from "./AboutMe/AboutMeField";
import DisplayNameField from "./DisplayNameField";
import HIVStatusField from "./HIVStatusField";
import HeightField from "./HeightField";
import ProfileFormSection from "./ProfileFormSection";
import SafetyField from "./SafetyField";
import ShowAgeField from "./ShowAgeField";
import {
	GendersField,
	IntoField,
	KinksField,
	LanguagesSpokenField,
	LookingForIdentitiesField,
	LookingForRolesField,
	LookingForTraitsField,
	MyIdentitiesField,
	MyRolesField,
	MyTraitsField,
	PronounsField,
	RelationshipsField,
	ScenariosField,
	SexualitiesField,
} from "./TagFields";
import VaccinationsField from "./VaccinationsField";

export const dontDisplay = "dont_display";

function isDeepEqual<T>(first: T, second: T) {
	try {
		deepStrictEqual(first, second);
		return true;
	} catch {
		return false;
	}
}

type ProfileFormValues = {
	display_name: string;
	about_me: string;
	languages_spoken: string[];
	birthdate: string;
	show_age: boolean;
	height: string;
	safety: string;
	hiv_status: string;
	sexualities: string[];
	gender_identities: string[];
	vaccinations: string[];
	relationships: string[];
	my_roles: string[];
	my_identities: string[];
	my_traits: string[];
	looking_for_roles: string[];
	looking_for_identities: string[];
	looking_for_traits: string[];
	scenarios: string[];
	into: string[];
	kinks: string[];
	pronouns: string[];
};

const focusOnError = createDecorator<ProfileFormValues>();

function valueOrNullIfDontDisplay(value: string) {
	return value === dontDisplay ? null : value;
}

export default function ProfileForm() {
	const { t } = useTranslation("common");
	const { mutate } = useSWRConfig();
	const user = assertType(useAccount());
	const updateAlert = useUpdateAlert();

	const onSubmit = makeHandleFormSubmit<ProfileFormValues>({
		submitter: async (values: ProfileFormValues) => {
			await updateProfile({
				display_name: values.display_name,
				about_me: values.about_me ?? "",
				languages_spoken: values.languages_spoken,
				birthdate: values.birthdate,
				show_age: values.show_age,
				height: valueOrNullIfDontDisplay(values.height),
				safety: valueOrNullIfDontDisplay(values.safety),
				hiv_status: valueOrNullIfDontDisplay(values.hiv_status),
				sexualities: values.sexualities,
				gender_identities: values.gender_identities,
				vaccinations: values.vaccinations,
				relationships: values.relationships,
				my_roles: values.my_roles,
				my_identities: values.my_identities,
				my_traits: values.my_traits,
				looking_for_roles: values.looking_for_roles,
				looking_for_identities: values.looking_for_identities,
				looking_for_traits: values.looking_for_traits,
				scenarios: values.scenarios,
				into: values.into,
				kinks: values.kinks,
				pronouns: values.pronouns,
			});
			mutate(accountRegisterPath);
			return undefined;
		},
		updateAlert,
		successMessage: t("profile.changed_saved_message"),
		unknownErrorMessage: t("unknown_error"),
	});
	return (
		<Form
			onSubmit={onSubmit}
			decorators={[focusOnError]}
			initialValues={{
				display_name: user.display_name,
				about_me: user.about_me,
				languages_spoken: user.languages_spoken,
				birthdate: user.birthdate,
				show_age: user.show_age,
				height: String(user.height ?? dontDisplay),
				safety: user.safety ?? dontDisplay,
				hiv_status: user.hiv_status ?? dontDisplay,
				sexualities: user.sexualities,
				gender_identities: user.gender_identities,
				vaccinations: user.vaccinations,
				relationships: user.relationships,
				my_roles: user.my_roles,
				my_identities: user.my_identities,
				my_traits: user.my_traits,
				looking_for_roles: user.looking_for_roles,
				looking_for_identities: user.looking_for_identities,
				looking_for_traits: user.looking_for_traits,
				scenarios: user.scenarios,
				into: user.into,
				kinks: user.kinks,
				pronouns: user.pronouns,
			}}
			keepDirtyOnReinitialize
			render={({ handleSubmit, hasValidationErrors, initialValues, values, submitting }) => {
				// final form has a `pristine`, but it does a shallow comparison, which fails
				// when applied to our array fields like gender.
				// we have to sort vaccinations for the comparison because they are unordered,
				// but multi-select treats them as ordered. We'll remove those remaps when the
				// server is updated for those items to be ordered, or multi-select is updated
				// to sort un-ordered items.
				const pristine = isDeepEqual(
					{
						...initialValues,
						vaccinations: (initialValues as ProfileFormValues).vaccinations.slice().sort(),
					},
					{
						...values,
						vaccinations: values.vaccinations.slice().sort(),
					},
				);
				return (
					<Box
						component="form"
						onSubmit={handleSubmit}
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: (theme) => theme.spacing(3),
						}}
					>
						<ProfileFormSection heading={t("profile.section_basic_info_heading")}>
							<DisplayNameField />
							<PronounsField />
							<AgeVerificationField />
							<ShowAgeField />
							<HeightField />
							<RelationshipsField />
						</ProfileFormSection>

						<ProfileFormSection heading={t("profile.section_about_me_heading")}>
							<AboutMeField />
							<LanguagesSpokenField />
						</ProfileFormSection>

						<ProfileFormSection heading={t("profile.section_details_heading")}>
							<SexualitiesField />
							<GendersField />
							<MyRolesField />
							<MyIdentitiesField />
							<MyTraitsField />
						</ProfileFormSection>

						<ProfileFormSection heading={t("profile.section_looking_for_heading")}>
							<LookingForRolesField />
							<LookingForIdentitiesField />
							<LookingForTraitsField />
						</ProfileFormSection>

						<ProfileFormSection heading={t("profile.section_turn_ons_heading")}>
							<ScenariosField />
							<IntoField />
							<KinksField />
						</ProfileFormSection>

						<ProfileFormSection heading={t("profile.section_health_safety_heading")}>
							<SafetyField />
							<HIVStatusField />
							<VaccinationsField />
						</ProfileFormSection>

						<Button
							type="submit"
							disabled={submitting}
							fullWidth
							variant={pristine || hasValidationErrors ? "outlined" : "contained"}
							sx={(theme) => {
								if (pristine || hasValidationErrors) {
									return {
										mb: 2,
									};
								}
								return {
									mb: 2,
									position: "sticky",
									bottom: theme.spacing(2),
								};
							}}
						>
							{pristine ? t("saved_button") : t("save_button")}
						</Button>
					</Box>
				);
			}}
		/>
	);
}
