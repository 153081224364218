import InformationPane from "@components/InformationPane";
import GlobeIcon from "@components/icons/GlobeIcon";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";

export default function RegionBlockedMapView() {
	const { t } = useTranslation("common");

	return (
		<Box
			sx={{
				position: "absolute",
				inset: 0,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<InformationPane
				Icon={GlobeIcon}
				title={t("region_blocked_map_pane.title")}
				subtitle={t("region_blocked_map_pane.subtitle")}
			/>
		</Box>
	);
}
